import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import MainLayout from "./_layouts/Main"
import EligibilityQuizStart from "../sections/EligibilityQuiz/EligibilityQuizStart"
import {
  useAccountId,
  useGetAttestationQuizes,
  useGetAttestationQuiz,
} from "../hooks"
import EligibilityQuizSteps from "../sections/EligibilityQuiz/EligibilityQuizSteps"

const EligibilityQuiz = () => {
  const accountId = useAccountId()
  const { quizCategory } = useParams()
  const [quizStep, setQuizStep] = useState(undefined)

  const { data: quizesData, isLoading: quizesIsLoading } =
    useGetAttestationQuizes(accountId)

  const { data: quizData, isLoading: quizIsLoading } = useGetAttestationQuiz(
    accountId,
    quizCategory,
    quizesData && quizesData[quizCategory]?.completed,
    {
      enabled: quizesData !== undefined,
    }
  )

  useEffect(() => {
    setQuizStep(undefined)
  }, [quizCategory])

  return (
    <MainLayout
      isLoading={[quizesIsLoading, quizIsLoading]}
      contentBg="white"
      verifyUserEmailVariant="generic"
    >
      {quizStep === undefined ? (
        <EligibilityQuizStart
          categoryClass={quizCategory}
          projectType={quizData?.quiz_data.project_type}
          startQuiz={() => setQuizStep(0)}
          questionsNum={quizData?.attestations.length}
        />
      ) : (
        <EligibilityQuizSteps
          quizesData={quizesData}
          attestations={quizData?.attestations}
          quizData={quizData?.quiz_data}
          quizStep={quizStep}
          setQuizStep={setQuizStep}
        />
      )}
    </MainLayout>
  )
}

export default EligibilityQuiz
