import React from "react"
import { Navigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import NothingFound from "../components/NothingFound"
import {
  useAccountId,
  useAccountUsers,
  useDashboardAccounts,
  useIsMultiAccount,
} from "../hooks"
import { AccountUsers } from "@/types/accountUsers"

const BrowsedAll = () => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const queryClient = useQueryClient()

  const { isLoading: accountUsersIsLoading } = useAccountUsers<
    AccountUsers,
    Error
  >(queryClient, "_single", {
    enabled: !isMultiAccount,
  })
  const { data: accountsData, isLoading: accountsIsLoading } =
    useDashboardAccounts(queryClient, {
      enabled: isMultiAccount,
    })

  if (accountsData?.count === 0) {
    return <Navigate replace to="/accounts/new" />
  }

  return (
    <MainLayout
      isLoading={[accountUsersIsLoading, accountsIsLoading]}
      verifyUserEmailVariant="generic"
    >
      <NothingFound
        title="That’s it!"
        text="You’ve browsed all available programs. Check back soon to see new opportunities."
        linkText="View Program List"
        to={
          isMultiAccount
            ? `/accounts/${accountId}/programs/page/1`
            : "/programs/page/1"
        }
      />
    </MainLayout>
  )
}

export default BrowsedAll
