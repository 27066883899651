import React, { ReactNode } from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

interface PrevTypes {
  prevLinkText: string
}

interface NextTypes {
  nextLinkText: string
}

interface PaginationLinkTypes {
  children: ReactNode
  to: string
  className: string
}

interface PaginationTypes {
  prevTo: string
  nextTo: string
  prevLinkText: string
  nextLinkText: string
  className: string
}

const Prev = ({ prevLinkText }: PrevTypes) => (
  <>
    <span className={cx("pagination-link-icon", "mr-2")}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </span>
    <span className="text-base font-semibold">
      {prevLinkText || "Previous"}
    </span>
  </>
)

const Next = ({ nextLinkText }: NextTypes) => (
  <>
    <span className="text-base font-semibold">{nextLinkText || "Next"}</span>
    <span className={cx("pagination-link-icon", "ml-2")}>
      <FontAwesomeIcon icon={faArrowRight} />
    </span>
  </>
)

const PaginationLink = ({ children, to, className }: PaginationLinkTypes) => {
  if (to === undefined) {
    return (
      <div className={cx("pagination-link-disabled", className)}>
        {children}
      </div>
    )
  }

  return (
    <Link to={to} className={cx("pagination-link", className)}>
      {children}
    </Link>
  )
}

const Pagination = ({
  prevTo,
  nextTo,
  prevLinkText,
  nextLinkText,
  className,
}: PaginationTypes) => {
  return (
    <div className={cx("pagination", className)}>
      <div className="pagination-container container">
        <PaginationLink to={prevTo} className="lg:mr-8">
          <Prev prevLinkText={prevLinkText} />
        </PaginationLink>

        <PaginationLink to={nextTo} className="lg:ml-8">
          <Next nextLinkText={nextLinkText} />
        </PaginationLink>
      </div>
    </div>
  )
}

export default Pagination
