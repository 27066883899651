import { useEffect, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Link } from "react-router-dom"
import DashboardLayout from "../_layouts/Dashboard"
import NotificationContent from "../../sections/ViewAccount/NotificationContent"

import {
  useAccountId,
  useAccount,
  useAccountProperty,
  useAccountNotifications,
  useProfile,
  useAccountUrlPrefix,
  useDashboardAccounts,
  useQueryParam,
  buildAccountAbsoluteUrl,
  useIsMultiAccount,
  useProjectList,
} from "../../hooks"
import { AcreageToolTip } from "../../components/AcreageToolTip"
import { Toast } from "../../components/Toast"
import { SubNavigation } from "../../components/SubNavigation"
import { shortenAcreage } from "../../utils"
import { useSessionStore } from "../../stores"
import { getProjectsData } from "../../shared/utils"
import ProjectInterestForm from "../../sections/ProjectInterest/ProjectInterestForm"
import EligibilityContent from "../../sections/ViewAccount/EligibilityContent"

import { ContentCard } from "../../components/Card"

import { LANDOWNER_STATUS } from "../../shared/constants"
import InviteYourNeighbor from "../../sections/ViewAccount/InviteYourNeighbor"
import { AccountTypes } from "../../types/account"
import { Profile } from "../..//types"
import { ProjectDataTypes, ProjectListTypes } from "../../types/program"
import { NotificationTypes } from "../../types/notifications"
import { AccountProperty } from "../../types/property"

const ViewAccount = ({ showGlobalNotifications = false }) => {
  // Load in our data/hooks
  const queryClient = useQueryClient()
  const isMultiAccount = useIsMultiAccount()
  const accountId = useAccountId()
  const accountUrlPrefix = useAccountUrlPrefix()
  const accountUrl = buildAccountAbsoluteUrl(accountId)
  const tipaltiEvent = useQueryParam("tipalti_event")

  // Load our data
  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)
  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)
  const { data: projectList, isLoading: projectListIsLoading } = useProjectList<
    ProjectListTypes,
    Error
  >(queryClient, accountId, {})
  const projectsData: ProjectDataTypes[] = getProjectsData(projectList)
  const { data: notifications, isLoading: notificationsIsLoading } =
    useAccountNotifications<NotificationTypes[], Error>(queryClient, accountId)
  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId)
  // DEV: We should only load this if a cycle is "created" but that's a dependent query (bad perf), so always fetch
  const { data: accounts, isLoading: accountsIsLoading } =
    useDashboardAccounts(queryClient)

  const accountsCount: number | undefined = accounts && accounts.count
  const { setAccountValueOverride } = useSessionStore((state) => state)

  const dataLoaded = !!(
    account &&
    accounts &&
    property &&
    notifications &&
    profile &&
    projectList
  )

  // DEV: Place `useEffect` before any `return` calls to avoid issues with rule of hooks
  useEffect(() => {
    if (!dataLoaded) {
      return
    }
    // When Tipalti redirects on completion, override the status
    if (tipaltiEvent === "payment_details_completed") {
      if (!account.payment_info_complete) {
        setAccountValueOverride(accountId, "payment_info_complete", true)
      }
    }
  }, [
    dataLoaded,
    accountId,
    tipaltiEvent,
    setAccountValueOverride,
    profile,
    account,
    projectList,
  ])

  // When Tipalti redirects on completion, notify our user
  // DEV: Don't group this with other querystring checks, that's a recipe for multiple notifications
  const [handledTipaltiEvent, setHandledTipaltiEvent] = useState(false)
  useEffect(() => {
    // Prevent infinite loops by tracking that we've run once
    // DEV: We could use querystring scrubbing, but it will get sticky for delegating to PaymentDeliveryFailedContent
    //   Additionally, it's a nice feature to re-surface modals on page refresh (e.g. accidental dismissal)
    if (handledTipaltiEvent) {
      return
    }
    if (tipaltiEvent === "payment_details_completed") {
      Toast.success("Payment information saved")
      setHandledTipaltiEvent(true)
    }
  }, [handledTipaltiEvent, tipaltiEvent])

  const isIneligible = projectsData?.every(
    (project) => project.badge_display === LANDOWNER_STATUS.ineligible
  )

  const isBeingAssessed = projectsData?.every(
    (project) =>
      !project.badge_display ||
      project.badge_display === LANDOWNER_STATUS.determining_eligibility
  )

  return (
    <DashboardLayout
      showGlobalNotifications={showGlobalNotifications}
      isLoading={[
        accountIsLoading,
        accountsIsLoading,
        propertyIsLoading,
        notificationsIsLoading,
        profileIsLoading,
        projectListIsLoading,
      ]}
      subNavigation={
        dataLoaded &&
        accountId !== "_single" && (
          <SubNavigation
            aria-label="Back to dashboard"
            title={account.account_name}
            backUrl="/"
            isAccountsPage={true}
          />
        )
      }
      verifyUserEmailVariant="generic"
    >
      {dataLoaded && (
        <div className="grid grid-cols-1 gap-6  md:grid-cols-3 md:gap-6">
          {/* DEV: If col-span-2 runs with grid-cols-1, then Firefox does strange horizontal sizing */}
          <div className="md:col-span-2 space-y-8">
            {/* DEV: Content is nested inside of column so the entire column doesn't get the full height card */}
            <NotificationContent
              accountId={accountId}
              accountsCount={accountsCount}
              notifications={notifications}
            />

            {!account?.has_project_interest_info && (
              <ContentCard>
                <h3 className="text-xl mb-6">
                  Tell us what type of programs you're interested in
                </h3>
                <div className="text-overline">Program Interest</div>
                <p className="mt-2 mb-8 md:mb-6 text-base leading-130">
                  We'll use this information to share opportunities that you're
                  interested in.
                </p>
                <ProjectInterestForm
                  accountData={account}
                  submitText="Save Program Interest"
                  onSuccess={() =>
                    Toast.success(`Program interest added to account`)
                  }
                />
              </ContentCard>
            )}
            <EligibilityContent
              accountId={accountId}
              isMultiAccount={isMultiAccount}
              isIneligible={isIneligible}
              isBeingAssessed={isBeingAssessed}
              property={property}
              profile={profile}
            />
          </div>
          <div>
            <div className="card card-content px-6 pt-6 pb-4 mb-6">
              <h4 className="text-overline mb-3">Property</h4>
              <div className="mb-6">
                <div className="big-stat mr-16">
                  <span className="big-stat-number">
                    {shortenAcreage(property.acreage)}
                  </span>
                  <span className="big-stat-unit">
                    total acres <AcreageToolTip />
                  </span>
                </div>
              </div>
              <div className="border-cloud mb-4">
                <Link
                  to={{
                    pathname: `${accountUrlPrefix}/settings/property-boundaries`,
                  }}
                  state={{ from: { pathname: accountUrl } }}
                  className="link"
                >
                  View Property Boundaries
                </Link>
              </div>
              <hr className="border-cloud mb-4" />
              <div>
                <Link to={`${accountUrlPrefix}/settings`} className="link">
                  Edit Account Settings
                </Link>
              </div>
            </div>

            <InviteYourNeighbor />
          </div>
        </div>
      )}
    </DashboardLayout>
  )
}

export default ViewAccount
