import React, { useRef } from "react"
import { Outlet } from "react-router-dom"
import { QueryStatus, useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import { NavBar } from "../../components/NavBar"
import { useProfile } from "../../hooks"
import { useAccessToken } from "../../stores"
import { NavLayoutProvider } from "../../context/NavLayoutProvider"
import { Profile } from "../../types"
import { NotificationTypes } from "../../types/notifications"

interface NavLayoutTypes {
  partnersNav?: boolean
  notifications?: NotificationTypes[]
  notificationsStatus: QueryStatus
  notificationsIsLoading: boolean
  bgClass?: string
}

const NavLayout = ({
  partnersNav = false,
  notifications = [],
  notificationsStatus,
  notificationsIsLoading,
  bgClass = "",
}: NavLayoutTypes) => {
  const accessToken = useAccessToken()
  const queryClient = useQueryClient()
  const ncxLogoRef = useRef<HTMLAnchorElement | null>(null)

  const { data: profile } = useProfile<Profile, Error>(queryClient, {
    enabled: !!accessToken,
  })

  return (
    <NavLayoutProvider
      value={{ ncxLogoRef, notificationsStatus, notificationsIsLoading }}
    >
      <div className={cx("flex flex-col min-h-full", bgClass)}>
        {!!accessToken && (
          <NavBar
            isLoggedIn={!!accessToken}
            isEmailVerified={!!profile?.is_email_verified}
            notifications={notifications}
            partnersNav={partnersNav}
          />
        )}
        <Outlet />
      </div>
    </NavLayoutProvider>
  )
}

export default NavLayout
