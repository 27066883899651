import { useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"

import {
  SentryRoutes,
  SentryRoute,
  ProtectedRoute,
  AccountComponent,
  SharedAuth,
  ProgramsRoutes,
  Redirect,
} from "../App"
import NavLayout from "../pages/_layouts/NavLayout"
import Logout from "../pages/Logout"
import GettingStarted from "../sections/GettingStarted"
import ProSignUp from "../pages/ProSignUp"
import ResetPasswordConfirm from "../pages/ResetPasswordConfirm"
import AccountInfo from "../pages/accounts/AccountInfo"
import OnboardingPropertyBoundaries from "../pages/onboarding/PropertyBoundaries"
import OnboardingSurvey from "../pages/onboarding/OnboardingSurvey"
import ProjectListing from "../pages/ProjectListing"
import ProjectDetails from "../pages/ProjectDetails"
import BrowsedAll from "../pages/BrowsedAll"
import SettingsAccountInfo from "../pages/settings/AccountInfo"
import SettingsContactInfo from "../pages/settings/ContactInfo"
import SettingsProjectInterest from "../pages/settings/ProjectInterest"
import SettingsChangePassword from "../pages/settings/ChangePassword"
import SettingsDocuments from "../pages/settings/Documents"
import SettingsPayment from "../pages/settings/Payment"
import SettingsPropertyDetails from "../pages/settings/PropertyDetails"
import SettingsPropertyBoundaries from "../pages/settings/PropertyBoundaries"
import SettingsUsers from "../pages/settings/Users"
import ViewAccount from "../pages/accounts/ViewAccount"
import Accounts from "../pages/Accounts"
import Inbox from "../pages/Inbox"
import Error404 from "../pages/Error404"
import EligibilityQuiz from "../pages/EligibilityQuiz"
import {
  useAccountId,
  useAccountNotifications,
  useIsMultiAccount,
} from "../hooks"
import { useAccessToken } from "../stores"
import { NotificationTypes } from "../types/notifications"
import { AuthDataTypes, SignUpDataTypes } from "../types/auth"

export const LandOwnersRouter = () => {
  const location = useLocation()
  const accessToken = useAccessToken()
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const [authData, setAuthData] = useState<AuthDataTypes | SignUpDataTypes>({})

  const isLoggedIn = !!accessToken

  const queryEnabled =
    isLoggedIn &&
    ((isMultiAccount && accountId !== "_single") || !isMultiAccount)

  const {
    data: notifications,
    status: notificationsStatus,
    isLoading: notificationsIsLoading,
  } = useAccountNotifications<NotificationTypes[], Error>(
    queryClient,
    accountId,
    {
      enabled: queryEnabled,
    }
  )

  const bgClass =
    location.pathname === "/pro-sign-up" ? "bg-pro-signup-img" : ""

  return (
    <SentryRoutes>
      <SentryRoute
        element={
          <NavLayout
            notifications={notifications}
            notificationsStatus={notificationsStatus}
            notificationsIsLoading={notificationsIsLoading}
            bgClass={bgClass}
          />
        }
      >
        <SentryRoute
          path="/create-account"
          // Lint ignore: Since this is a one-off Redirect, we don't care about virtual DOM constructor preservation
          element={
            <Navigate
              replace
              to={{ ...location, pathname: "/getting-started" }}
              state={{ authStep: "signUp" }}
            />
          }
        />

        <SentryRoute element={<SharedAuth />}>
          <SentryRoute
            path="/sign-up"
            element={
              // we have instances of /sign-up out in the world so we preserve direct link to sign-up page
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
                state={{ authStep: "signUp" }}
              />
            }
          />
          <SentryRoute
            path="/vip"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
              />
            }
          />
          <SentryRoute
            path="/login"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
              />
            }
          />
          <SentryRoute
            path="/reset-password"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
                state={{ authStep: "resetPassword" }}
              />
            }
          />
          <SentryRoute
            path="/getting-started"
            element={
              <GettingStarted authData={authData} setAuthData={setAuthData} />
            }
          />
          <SentryRoute
            path="/pro-sign-up"
            element={
              <ProSignUp authData={authData} setAuthData={setAuthData} />
            }
          />
        </SentryRoute>

        <SentryRoute path="/logout" element={<Logout />} />
        <SentryRoute
          path="/reset-password-confirm"
          element={<ResetPasswordConfirm />}
        />

        <SentryRoute element={<ProtectedRoute />}>
          {/* Personal settings routes */}
          <SentryRoute
            path="/settings"
            element={<Navigate replace to="/settings/contact-info" />}
          />
          <SentryRoute
            path="/settings/project-interest"
            element={<Navigate replace to="/settings/program-interest" />}
          />
          <SentryRoute
            path="/settings/change-password"
            element={<SettingsChangePassword />}
          />
          <SentryRoute
            path="/settings/contact-info"
            element={<SettingsContactInfo />}
          />

          <SentryRoute
            path="/settings/program-interest"
            element={<SettingsProjectInterest />}
          />

          {/* Account settings routes */}
          <SentryRoute
            path="/accounts/:accountId/settings"
            element={
              <Redirect to="/accounts/:accountId/settings/account-info" />
            }
          />
          <SentryRoute
            path="/accounts/:accountId/settings/account-info"
            element={<SettingsAccountInfo />}
          />
          <SentryRoute
            path="/settings/documents"
            element={<AccountComponent component={SettingsDocuments} />}
          />
          <SentryRoute
            path="/accounts/:accountId/settings/documents"
            element={<AccountComponent component={SettingsDocuments} />}
          />
          {/* ASANA: If this id ever changes, ensure Tipalti also gets updated as well, since we're referencing this specific URL in a Tipalti email template. */}
          {/* https://app.asana.com/0/1198952737412966/1203482757456891/f */}
          <SentryRoute
            path="/settings/payment"
            element={<AccountComponent component={SettingsPayment} />}
          />
          <SentryRoute
            path="/accounts/:accountId/settings/payment"
            element={<AccountComponent component={SettingsPayment} />}
          />
          <SentryRoute
            path="/settings/property-details"
            element={<AccountComponent component={SettingsPropertyDetails} />}
          />
          <SentryRoute
            path="/accounts/:accountId/settings/property-details"
            element={<AccountComponent component={SettingsPropertyDetails} />}
          />
          <SentryRoute
            path="/settings/property-boundaries"
            element={
              <AccountComponent component={SettingsPropertyBoundaries} />
            }
          />
          <SentryRoute
            path="/accounts/:accountId/settings/property-boundaries"
            element={
              <AccountComponent component={SettingsPropertyBoundaries} />
            }
          />
          <SentryRoute
            path="/settings/users"
            element={<AccountComponent component={SettingsUsers} />}
          />
          <SentryRoute
            path="/accounts/:accountId/settings/users"
            element={<AccountComponent component={SettingsUsers} />}
          />

          <SentryRoute
            path="/onboarding"
            element={<Navigate replace to="/onboarding/property-boundaries" />}
          />
          <SentryRoute
            path="/accounts/:accountId/onboarding"
            element={
              <Redirect to="/accounts/:accountId/onboarding/account-info" />
            }
          />
          <SentryRoute
            path="/accounts/new"
            element={
              <AccountComponent allowMissingId={true} component={AccountInfo} />
            }
          />
          <SentryRoute
            path="/accounts/:accountId/onboarding/account-info"
            element={
              <AccountComponent allowMissingId={true} component={AccountInfo} />
            }
          />
          <SentryRoute
            path="/onboarding/property-boundaries"
            element={
              <AccountComponent component={OnboardingPropertyBoundaries} />
            }
          />
          <SentryRoute
            path="/accounts/:accountId/onboarding/property-boundaries"
            element={
              <AccountComponent component={OnboardingPropertyBoundaries} />
            }
          />
          <SentryRoute
            path="/onboarding/goals"
            element={<AccountComponent component={OnboardingSurvey} />}
          />
          <SentryRoute
            path="/accounts/:accountId/onboarding/goals"
            element={<AccountComponent component={OnboardingSurvey} />}
          />
          <SentryRoute
            path="/onboarding/report"
            element={<AccountComponent component={OnboardingSurvey} />}
          />
          <SentryRoute
            path="/accounts/:accountId/onboarding/report"
            element={<AccountComponent component={OnboardingSurvey} />}
          />

          {/* Projects routes */}
          <SentryRoute
            path="/projects"
            element={<Navigate replace to="/programs/page/1" />}
          />
          <SentryRoute
            path="/programs"
            element={<Navigate replace to="/programs/page/1" />}
          />
          <SentryRoute
            path="/accounts/:accountId/projects"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/page/1"
                    : "/programs/page/1"
                }
              />
            }
          />
          <SentryRoute
            path="/accounts/:accountId/programs"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/page/1"
                    : "/programs/page/1"
                }
              />
            }
          />
          <SentryRoute
            path="/projects/page/:pageId"
            element={<Redirect to="/programs/page/:pageId" />}
          />
          <SentryRoute
            path="/accounts/:accountId/projects/page/:pageId"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/page/:pageId"
                    : "/programs/page/1"
                }
              />
            }
          />
          <SentryRoute
            path="/projects/browsed-all"
            element={<Navigate replace to="/programs/browsed-all" />}
          />
          <SentryRoute
            path="/accounts/:accountId/projects/browsed-all"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/browsed-all"
                    : "/programs/browsed-all"
                }
              />
            }
          />
          <SentryRoute
            path="/projects/:projectId"
            element={<Redirect to="/programs/:projectId" />}
          />
          <SentryRoute
            path="/accounts/:accountId/projects/:projectId"
            element={
              <Redirect
                to={
                  isMultiAccount
                    ? "/accounts/:accountId/programs/:projectId"
                    : "/programs/:projectId"
                }
              />
            }
          />

          <SentryRoute element={<ProgramsRoutes />}>
            <SentryRoute
              path="/programs/page/:pageId"
              element={
                <AccountComponent
                  component={ProjectListing}
                  redirectPath="/accounts"
                />
              }
            />
            <SentryRoute
              path="/accounts/:accountId/programs/page/:pageId"
              element={
                <AccountComponent
                  component={ProjectListing}
                  redirectPath="/accounts"
                />
              }
            />
            <SentryRoute
              path="/programs/browsed-all"
              element={<BrowsedAll />}
            />
            <SentryRoute
              path="/accounts/:accountId/programs/browsed-all"
              element={<BrowsedAll />}
            />
            <SentryRoute
              path="/programs/:projectId"
              element={
                <AccountComponent
                  component={ProjectDetails}
                  redirectPath="/accounts"
                />
              }
            />
            <SentryRoute
              path="/accounts/:accountId/programs/:projectId"
              element={
                <AccountComponent
                  component={ProjectDetails}
                  redirectPath="/accounts"
                />
              }
            />
          </SentryRoute>

          <SentryRoute
            path="/eligibility-quiz/:quizCategory"
            element={
              <AccountComponent
                component={EligibilityQuiz}
                redirectPath="/accounts"
              />
            }
          />
          <SentryRoute
            path="/accounts/:accountId/eligibility-quiz/:quizCategory"
            element={
              <AccountComponent
                component={EligibilityQuiz}
                redirectPath="/accounts"
              />
            }
          />

          <SentryRoute
            path="/accounts/:accountId/inbox"
            element={
              <AccountComponent component={Inbox} redirectPath="/accounts" />
            }
          />
          <SentryRoute
            path="/inbox"
            element={
              <AccountComponent component={Inbox} redirectPath="/accounts" />
            }
          />

          <SentryRoute
            path="/style-guide"
            element={<Navigate replace to="/design-system" />}
          />

          <SentryRoute
            path="/"
            element={
              isMultiAccount ? (
                <Navigate replace to="/accounts" />
              ) : (
                <ViewAccount />
              )
            }
          />
          <SentryRoute path="/accounts" element={<Accounts />} />
          <SentryRoute
            path="/accounts/:accountId"
            element={<AccountComponent component={ViewAccount} />}
          />
        </SentryRoute>

        <SentryRoute path="*" Component={Error404} />
      </SentryRoute>
    </SentryRoutes>
  )
}
