import { createContext, MutableRefObject } from "react"
import { QueryStatus } from "@tanstack/react-query"

export interface NavLayoutContextTypes {
  ncxLogoRef: MutableRefObject<HTMLAnchorElement | null>
  notificationsStatus: QueryStatus
  notificationsIsLoading: boolean
}

export const NavLayoutContext = createContext<NavLayoutContextTypes | null>(
  null
)
