import { useNavigate, useLocation } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useDialogState } from "reakit/Dialog"

import MainLayout from "../_layouts/Main"
import Property from "../../sections/Property"
import {
  useAccountId,
  useAccountProperty,
  useAccountUrlPrefix,
} from "../../hooks"
import { AccountProperty } from "../../types/property"

const OnboardingPropertyBoundaries = () => {
  // Define our hooks
  const accountId = useAccountId()
  const navigate = useNavigate()
  const location = useLocation()
  const accountUrlPrefix = useAccountUrlPrefix()
  const queryClient = useQueryClient()
  const uploadDialog = useDialogState({ animated: true })

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId)

  const handleSuccess = () => {
    if (location?.state?.hasCompletedSurvey) {
      navigate(`${accountUrlPrefix}/onboarding/report`, {
        state: { refreshPropertyBoundaries: true },
      })
    } else {
      navigate(`${accountUrlPrefix}/onboarding/goals`)
    }
  }

  return (
    <MainLayout
      isLoading={[propertyIsLoading]}
      contentBg="white"
      verifyUserEmailVariant="generic"
    >
      {property && (
        <Property
          property={property}
          hidden={false}
          submitText="Save and Continue"
          onSuccess={handleSuccess}
          uploadDialog={uploadDialog}
        />
      )}
    </MainLayout>
  )
}

export default OnboardingPropertyBoundaries
