import { useState, useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useLocation } from "react-router-dom"

import MainLayout from "../_layouts/Main"
import {
  useAccountId,
  useAccountProperty,
  useProfile,
  useGetAttestationQuizes,
} from "../../hooks"
import OnboardingSteps from "../../sections/Onboarding/OnboardingSteps"
import OnboardingReport from "../../sections/Onboarding/OnboardingReport"
import { Profile } from "../../types"
import { AccountProperty } from "../../types/property"
import { EligibilityQuizzes } from "@/types/eligibility"

const OnboardingSurvey = () => {
  const accountId = useAccountId()
  const [onboardingStep, setOnboardingStep] = useState(0)
  const [surveyCompleted, setSurveyCompleted] = useState(false)
  const [showReport, setShowReport] = useState(false)

  const location = useLocation()
  const queryClient = useQueryClient()
  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)
  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId, {
    refetchInterval: (query) => {
      if (query.state?.data?.landcover_data || showReport) {
        return false
      }
      return 2000
    },
  })

  // this data connects project types to quiz categories for the report page
  const { data: quizzesData, isLoading: quizDataIsLoading } =
    useGetAttestationQuizes<EligibilityQuizzes, Error>(accountId)

  useEffect(() => {
    setOnboardingStep(0)
  }, [accountId])

  useEffect(() => {
    // send user to loading screen if they added parcels after survey
    if (location?.state?.refreshPropertyBoundaries) {
      setSurveyCompleted(true)
    }
    // otherwise, we we are coming from the loading screen so show report
    if (
      location?.pathname.includes("/onboarding/report") &&
      !location?.state?.refreshPropertyBoundaries
    ) {
      setShowReport(true)
    }
  }, [location?.pathname, location?.state?.refreshPropertyBoundaries])

  const progressStep = showReport ? 7 : surveyCompleted ? 6 : onboardingStep + 1

  return (
    <MainLayout
      isLoading={[quizDataIsLoading, profileIsLoading, propertyIsLoading]}
      contentBg="white"
      verifyUserEmailVariant="generic"
      className="onboarding"
    >
      {showReport ? (
        <OnboardingReport
          accountId={accountId}
          profile={profile as Profile}
          property={property as AccountProperty}
          quizzesData={quizzesData as EligibilityQuizzes}
          progressStep={progressStep}
          maxSteps={7}
        />
      ) : (
        <>
          <OnboardingSteps
            onboardingStep={onboardingStep}
            setOnboardingStep={setOnboardingStep}
            surveyCompleted={surveyCompleted}
            setSurveyCompleted={setSurveyCompleted}
            progressStep={progressStep}
            maxSteps={7}
          />
        </>
      )}
    </MainLayout>
  )
}

export default OnboardingSurvey
