import React, { ReactNode } from "react"
import { NavLayoutContext, NavLayoutContextTypes } from "./NavLayoutContext"

interface NavLayoutProviderTypes {
  value: NavLayoutContextTypes
  children: ReactNode
}

export const NavLayoutProvider = ({
  children,
  value,
}: NavLayoutProviderTypes) => (
  <NavLayoutContext.Provider value={value}>
    {children}
  </NavLayoutContext.Provider>
)
