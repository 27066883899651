import { Link, useLocation } from "react-router-dom"
import { useMenuState, Menu, MenuItem, MenuButton } from "reakit/Menu"
import { useQueryClient, useQuery } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"

import { useIsMultiAccount, useProfile } from "../hooks"
import { startIdentityVerification } from "../shared/utils"
import { Profile } from "../types"

interface PlatformUserMenuTypes {
  isEmailVerified: boolean
  partnersNav: boolean
}

export const PlatformUserMenu = ({
  isEmailVerified,
  partnersNav,
}: PlatformUserMenuTypes) => {
  const queryClient = useQueryClient()
  // DEV: Using react-query cache as a store to share "state" with ViewCount component
  const { data: idvRequestState } = useQuery({
    queryKey: ["idv-request-state"],
    queryFn: () => "incomplete",
  })

  const menu = useMenuState()

  const isMultiAccount = useIsMultiAccount()

  const { data: profile } = useProfile<Profile, Error>(queryClient)

  let fullName = `${profile?.first_name || ""} ${
    profile?.last_name || ""
  }`.trim()
  let abbreviatedName = `${profile?.first_name || ""} ${
    profile?.last_name ? profile.last_name.charAt(0) + "." : ""
  }`.trim()
  fullName = fullName || profile?.email || ""
  abbreviatedName = abbreviatedName || profile?.email || ""

  const unverified =
    [undefined, "created", "pending", "expired"].includes(
      profile?.identity_verification?.status
    ) && idvRequestState !== "complete"

  const location = useLocation()
  const isOnboarding = location.pathname.includes("/onboarding")
  const isEmailVerifiedLO = isEmailVerified && partnersNav === false

  return (
    <>
      <MenuButton
        // {/* DEV: `overflow-hidden` is required for inner `truncate` to function */}
        className="relative media overflow-hidden text-white text-base px-2 py-4 ml-1 md:ml-3"
        data-test="menu-button"
        {...menu}
      >
        <span className="media-body truncate sm:hidden text-white">
          {abbreviatedName}
        </span>

        <span className="hidden media-body truncate sm:block font-semibold text-white">
          {fullName}
        </span>

        <i className="media-figure ml-2">
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        </i>

        {idvRequestState === "pending" ? (
          <span className="absolute left-0 right-5 bottom-2 sm:bottom-0.5 text-center text-sm text-white italic">
            <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin" />
          </span>
        ) : null}
      </MenuButton>
      <Menu
        className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5 min-w-32 z-overlay"
        {...menu}
        aria-label="User Action Menu"
      >
        {isEmailVerifiedLO && unverified && !isOnboarding ? (
          <MenuItem
            className="block px-4 py-2 text-sm text-gray-700 focus:ring-0 focus:underline link--underline-only"
            {...menu}
            as={Link}
            to="/settings"
            onClick={() => {
              menu.hide()
              startIdentityVerification(queryClient, idvRequestState)
            }}
          >
            <span className="font-bold">Verify your identity</span>
          </MenuItem>
        ) : null}

        {isEmailVerifiedLO && !isOnboarding ? (
          <MenuItem
            className="block px-4 py-2 text-sm text-gray-700 focus:ring-0 focus:underline link--underline-only"
            {...menu}
            as={Link}
            to="/settings"
            onClick={menu.hide}
          >
            {/* DEV: Both are personal settings but as a single-account profile,
                everything looks like "Account Settings" so this is consistent with user expectations */}
            {isMultiAccount ? "Personal Settings" : "Account Settings"}
          </MenuItem>
        ) : null}

        <MenuItem
          className="block px-4 py-2 text-sm text-gray-700 focus:ring-0 focus:underline link--underline-only"
          {...menu}
          as={Link}
          to="/logout"
          data-test="logout"
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}
