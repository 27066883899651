import { useQueryClient } from "@tanstack/react-query"
import { DialogDisclosure, useDialogState } from "reakit/Dialog"

import SettingsLayout from "../_layouts/Settings"
import { DynamicTipaltiIframe } from "../../components/TipaltiIframe"
import { Modal } from "../../components/Modal"
import {
  useAccount,
  useAccountId,
  useAccountLatestAccountCycle,
  useAccountPaymentMethod,
  useQueryParam,
} from "../../hooks"
import { PaymentInfoPrompt } from "../../sections/PaymentInfoPrompt"
import { WistiaVideoModal } from "../../components/WistiaVideoModal"
import { CycleBadge } from "../../components/Badge"
import { AccountTypes } from "../../types/account"
import { PaymentMethod } from "../../types/payment"
import {
  AccountCycleStatusType,
  AccountCycleTypes,
} from "../../types/accountCycles"

const Payment = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)
  const { data: paymentMethod, isLoading: paymentMethodIsLoading } =
    useAccountPaymentMethod<PaymentMethod, Error>(queryClient, accountId)
  const {
    data: latestAccountCycleData,
    isLoading: latestAccountCycleDataIsLoading,
  } = useAccountLatestAccountCycle(queryClient, accountId)

  const tipaltiEvent = useQueryParam("tipalti_event")

  const paymentInfoDialog = useDialogState({ animated: true })
  const paymentInformationVideoDialog = useDialogState({ animated: true })

  const paymentInfoComplete = account?.payment_info_complete

  const dataLoaded = !!(account && paymentMethod && latestAccountCycleData)

  let badgeStatus: AccountCycleStatusType | undefined
  if (dataLoaded) {
    if (paymentMethod.is_payable) {
      badgeStatus = "payment_verified"
    } else if (!paymentMethod.is_payable && tipaltiEvent == null) {
      badgeStatus = "payment_info_needed"
    } else if (
      !paymentMethod.is_payable &&
      tipaltiEvent === "payment_details_completed"
    ) {
      badgeStatus = "payment_pending_approval"
    }
  }

  const paymentMethodStatusBadge = (
    <CycleBadge
      account={account}
      accountCycle={latestAccountCycleData as AccountCycleTypes}
      forceStatus={badgeStatus}
    />
  )

  const modalTitle = paymentInfoComplete
    ? "Edit payment information"
    : "Add Payment Info"

  return (
    <SettingsLayout
      isLoading={[
        accountIsLoading,
        paymentMethodIsLoading,
        latestAccountCycleDataIsLoading,
      ]}
      title="Payment"
      paymentMethodStatusBadge={paymentMethodStatusBadge}
    >
      {dataLoaded && (
        <div className="space-y-10">
          <div className="space-y-4">
            {/* DEV: NoPM means "no payment method" in Tipalti, so no sense in rendering it here */}
            {paymentMethod.tipalti_payment_method &&
              paymentMethod.tipalti_payment_method !== "NoPM" && (
                <p>
                  <strong>Payment method:</strong>{" "}
                  {paymentMethod.tipalti_payment_method}
                </p>
              )}
            {paymentMethod.bank_name && (
              <p>
                <strong>Bank name:</strong> {paymentMethod.bank_name}
              </p>
            )}
            <p>
              NCX uses a third-party provider, Tipalti, to send payments
              directly to your account.
            </p>
            <p>
              Want to get your payment faster? Select ACH (electronic fund
              transfer) to reduce delivery time and track payment. If you don't
              select ACH, you'll receive a check which can take between 7-10
              business days to arrive.
            </p>
            <DialogDisclosure
              className="btn2 btn2-primary font-semibold"
              {...paymentInfoDialog}
            >
              {paymentInfoComplete
                ? "Edit your payment information"
                : "Add Payment Info"}
            </DialogDisclosure>

            <Modal
              // DEV: Use `closeMode` to avoid expired iframes persisting, https://app.asana.com/0/0/1201766158954936/f
              closeMode="destroy"
              header={modalTitle}
              aria-label={modalTitle}
              // Prevent accidental closes, as per user testing: https://app.asana.com/0/1201467379381113/1202050023464744/f
              hideOnEsc={false}
              hideOnClickOutside={false}
              // DEV: Use bottom margin inside since padding is ignored when we need to scroll
              className="px-6 pt-6 pb-0 max-w-3xl"
              dialog={paymentInfoDialog}
            >
              <div className="mb-8">
                <PaymentInfoPrompt account={account} editMode="inline" />
              </div>
            </Modal>
          </div>
          {/* ASANA: If this id ever changes, ensure Tipalti also gets updated as well, since we're referencing this specific URL in a Tipalti email template. */}
          {/* https://app.asana.com/0/1198952737412966/1203482757456891/f */}
          <WistiaVideoModal
            id="tioyrucw50"
            title="Providing Payment Information to NCX"
            dialog={paymentInformationVideoDialog}
          />
          <DynamicTipaltiIframe
            tipaltiIframeType="PaymentHistory"
            className="border-smoke border-1"
          />
        </div>
      )}
    </SettingsLayout>
  )
}

export default Payment
