import { ComponentType } from "react"
import { useQueryClient } from "@tanstack/react-query"

import SettingsLayout from "../_layouts/Settings"
import UploadedDocumentsSection from "../../sections/Documents/UploadedDocumentsSection"
import CreatedDocumentsSection from "../../sections/Documents/CreatedDocumentsSection"
import {
  useAccountId,
  useAccountAccountCycles,
  useAccountOwnershipVerification,
} from "../../hooks"
import { AccountCycleTypes } from "../../types/accountCycles"
import { OwnershipVerificationTypes } from "../../types/ownershipVerification"
import {
  ACCOUNT_CYCLE_DOCUMENT_CONFIG,
  ACCOUNT_DOCUMENT_CONFIG,
} from "../../sections/Documents/documentsHelpers"

const Documents = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const {
    data: ownershipDocumentsData,
    isLoading: ownershipDocumentsIsLoading,
  } = useAccountOwnershipVerification<OwnershipVerificationTypes, Error>(
    queryClient,
    accountId
  )
  const { data: accountCyclesData, isLoading: accountCyclesDataIsLoading } =
    useAccountAccountCycles<AccountCycleTypes[], Error>(queryClient, accountId)
  // One day this may move to `object_list`, but not today, https://app.asana.com/0/1201653128864814/1201759560003317/f
  const accountCycles = accountCyclesData?.filter(
    (accountCycle: AccountCycleTypes) => accountCycle?.cycle_key !== "23D"
  )
  const ownershipDocuments = ownershipDocumentsData

  const dataLoaded = !!accountCyclesData && !!ownershipDocumentsData
  let accountCyclesToRender: {
    accountCycle: AccountCycleTypes
    createdDocumentComponents: ComponentType<any>[]
  }[] = []
  let uploadedDocumentComponents: ComponentType<any>[] = []

  if (dataLoaded) {
    const accountCycleComponents = accountCycles?.map((accountCycle) => {
      const createdDocumentComponents = ACCOUNT_CYCLE_DOCUMENT_CONFIG.filter(
        ({ condition }) => condition(accountCycle)
      ).map(({ component }) => component)

      return {
        accountCycle,
        createdDocumentComponents,
      }
    })

    const accountCyclesWithComponents = accountCycleComponents?.filter(
      ({ createdDocumentComponents }) => createdDocumentComponents.length
    )
    const accountCyclesWithoutComponents = accountCycleComponents?.filter(
      ({ createdDocumentComponents }) => !createdDocumentComponents.length
    )

    // Normally, we only want to render cycles with DocumentLinks
    accountCyclesToRender = accountCyclesWithComponents?.slice() || []
    // However, they may have re-enrollment open (or no DocumentLink cycles at all)
    //   and so we push the only non-empty one (most recent one) onto our array
    // DEV: Order is guaranteed by our server
    if (
      accountCyclesWithoutComponents?.length &&
      accountCycleComponents &&
      accountCyclesWithoutComponents[0] === accountCycleComponents[0]
    ) {
      accountCyclesToRender.unshift(accountCyclesWithoutComponents[0])
    }

    uploadedDocumentComponents = ACCOUNT_DOCUMENT_CONFIG.map(
      ({ component }) => component
    )
  }

  return (
    <SettingsLayout
      isLoading={[accountCyclesDataIsLoading, ownershipDocumentsIsLoading]}
      title="Documents"
    >
      {dataLoaded && (
        <>
          <UploadedDocumentsSection
            documentComponents={uploadedDocumentComponents}
            ownershipDocuments={ownershipDocuments}
          />
          <hr className="border-cloud mt-6 mb-6" />
          {accountCyclesToRender.map(
            ({ accountCycle, createdDocumentComponents }) => (
              <CreatedDocumentsSection
                accountId={accountId}
                accountCycle={accountCycle}
                documentComponents={createdDocumentComponents}
                key={accountCycle.cycle_key}
              />
            )
          )}
        </>
      )}
    </SettingsLayout>
  )
}

export default Documents
