import React from "react"
import { Modal } from "./Modal"
import { WistiaVideo } from "./WistiaVideo"
import { DialogStateReturn } from "reakit"

interface WistiaVideoModalTypes {
  id: string
  title: string
  dialog: DialogStateReturn
}

export const WistiaVideoModal = ({
  id,
  title,
  dialog,
}: WistiaVideoModalTypes) => {
  return (
    <Modal header={title} dialog={dialog} aria-label="Wistia video">
      <div>
        {/* DEV: Use `visible` to auto-stop video by clearing from DOM */}
        {/* DEV: Use `animating` to prevent eagerly removing video from modal before it's hidden */}
        {(dialog.visible || dialog.animating) && (
          <WistiaVideo id={id} title={title} className="w-full" />
        )}
      </div>
    </Modal>
  )
}
