import { useContext, useEffect, useState } from "react"
import { useParams, Navigate, useLocation } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useDialogState } from "reakit/Dialog"

import MainLayout from "./_layouts/Main"
import Error404 from "./Error404"
import NothingFound from "../components/NothingFound"
import SimilarProgramsSlider from "../components/SimilarProgramsSlider"
import Pagination from "../components/Pagination"
import BackToTop from "../components/BackToTop"
import ProjectDetailsHero from "../sections/ProjectDetails/ProjectDetailsHero"
import ProjectNotification from "../sections/ProjectDetails/ProjectNotification"
import ProjectDetailsContent from "../sections/ProjectDetails/ProjectDetailsContent"
import ProjectDetailsSidebar from "../sections/ProjectDetails/ProjectDetailsSidebar"
import Skeleton from "../sections/ProjectDetails/Skeleton"
import RoiModal from "../sections/ProjectDetails/Roi/RoiModal"
import ProjectMap from "../sections/ProjectDetails/ProjectMap/ProjectMap"
import HowWeAssessYourLandModal from "../sections/ProjectDetails/ProjectMap/HowWeAssessYourLandModal"
import ProjectDetailsTabs from "../sections/ProjectDetails/ProjectDetailsTabs"
import InvitationBanner from "../sections/ProjectDetails/InvitationBanner"
import {
  useAccountUsers,
  useDashboardAccounts,
  useIsMultiAccount,
  useProfile,
  useAccountId,
  useProjectList,
  useAttestations,
  useAccount,
  useRoi,
  useStackables,
  useAccountProperty,
  useGetTileUrls,
  useGetParcelAssessment,
  useAccountNotifications,
} from "../hooks"
import useLocalStorage from "../hooks/useLocalStorage"
import {
  filterProjectsData,
  getProjectData,
  getProjectsData,
  getStackableProjectsData,
} from "../shared/utils"
import { ProjectsContext } from "../context/ProjectsContext"
import { LANDOWNER_STATUS, PROJECT_SORTING } from "../shared/constants"
import { getPrevAndNext } from "../shared/getPrevAndNext"

const ProjectDetails = () => {
  const {
    state: {
      category,
      govtProjectsOn,
      ineligibleProjectsOn,
      hasLandownerCost,
      termLengthFilter,
      paymentTypes,
      searchProjects,
    },
  } = useContext(ProjectsContext)

  let { projectId } = useParams()
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const [sortBy] = useLocalStorage(
    "sortProjectsBy",
    PROJECT_SORTING.RECENTLY_ADDED
  )
  const location = useLocation()
  const isMultiAccount = useIsMultiAccount()
  const howWeAssessYourLandDialog = useDialogState({ animated: true })
  const eligibilityDialog = useDialogState({ animated: true })

  const {
    data,
    status: queryStatus,
    isLoading: queryIsLoading,
  } = useProjectList(queryClient, accountId, {})
  const { data: stackableProjectsIds, isLoading: stackablesIsLoading } =
    useStackables(queryClient, accountId, projectId)
  const { data: property, isLoading: propertyIsLoading } = useAccountProperty(
    queryClient,
    accountId
  )
  const { data: tileData, isLoading: tileIsLoading } = useGetTileUrls(
    accountId,
    projectId
  )
  const { data: parcelData, isLoading: parcelIsLoading } =
    useGetParcelAssessment(accountId, projectId)

  const projectsData = getProjectsData(data)
  const filteredProjectsData = filterProjectsData(
    projectsData,
    sortBy,
    category,
    govtProjectsOn,
    ineligibleProjectsOn,
    hasLandownerCost,
    termLengthFilter,
    paymentTypes,
    searchProjects
  )
  const projectData = getProjectData(data, projectsData, Number(projectId))

  const similarPrograms = filteredProjectsData?.filter((project) => {
    const projectFilter =
      project.type === projectData.type &&
      project.id !== projectData.id &&
      (project.badge_display === LANDOWNER_STATUS.eligible ||
        project.badge_display === LANDOWNER_STATUS.information_needed)

    return projectFilter
  })

  const stackablePrograms = getStackableProjectsData(
    stackableProjectsIds,
    projectsData
  )

  const { data: attestationsData, isLoading: attestationsIsLoading } =
    useAttestations(queryClient, accountId, projectId, {})
  const { data: profile, isLoading: profileIsLoading } = useProfile(queryClient)
  const { isLoading: accountUsersIsLoading } = useAccountUsers(
    queryClient,
    "_single",
    {
      enabled: !isMultiAccount,
    }
  )
  const { data: accountsData, isLoading: accountsIsLoading } =
    useDashboardAccounts(queryClient, {
      enabled: isMultiAccount,
    })
  const { data: account, isLoading: accountIsLoading } = useAccount(
    queryClient,
    accountId
  )

  const [roiCalcDefaults, setRoiCalcDefaults] = useState(null)
  const {
    data: roiData,
    isLoading: roiIsLoading,
    isSuccess: roiIsSuccess,
  } = useRoi(queryClient, accountId, projectId)

  useEffect(() => {
    if (roiData && !roiCalcDefaults && roiIsSuccess) {
      setRoiCalcDefaults(roiData?.calculator_inputs)
    }
  }, [roiData, roiCalcDefaults, roiIsSuccess])

  const roiDialog = useDialogState({ animated: true })

  const showRoi =
    typeof roiData === "object" &&
    roiData.total_revenue !== undefined &&
    roiData.total_profit !== undefined &&
    roiData.yearly_revenue !== undefined &&
    roiData.yearly_cost !== undefined &&
    roiData.yearly_profit !== undefined &&
    roiData.yearly_npv !== undefined

  const isUrgent = projectData?.is_urgent

  const { data: notifications, isLoading: notificationsIsLoading } =
    useAccountNotifications(queryClient, accountId)

  const invite = notifications?.find(
    (notification) =>
      notification.key === "pd_invite" &&
      notification?.extra_fields.project_id === Number(projectId)
  )

  const [acceptInvite, setAcceptInvite] = useState(null)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    })
  }, [projectId])

  useEffect(() => {
    if (location?.state?.acceptInvite) {
      setAcceptInvite(true)
    }
    if (location?.state?.acceptInvite === false) {
      setAcceptInvite(false)
    }

    return () => setAcceptInvite(null)
  }, [location?.state?.acceptInvite])

  const { prevPage, nextPage } = getPrevAndNext(
    filteredProjectsData,
    Number(projectId)
  )

  // DEV: MA users with no accounts get an error, so we redirect them to the account creation page
  if (accountsData?.count === 0) {
    return <Navigate replace to="/accounts/new" />
  }

  if (queryStatus === "success" && projectData === undefined) {
    return <Error404 />
  }

  return (
    <MainLayout
      isLoading={[
        profileIsLoading,
        accountsIsLoading,
        accountUsersIsLoading,
        queryIsLoading,
        attestationsIsLoading,
        accountIsLoading,
        roiIsLoading,
        stackablesIsLoading,
        propertyIsLoading,
        notificationsIsLoading,
      ]}
      verifyUserEmailVariant="generic"
      loader={<Skeleton />}
      errorPage={
        isMultiAccount && location.pathname === `/programs/${projectId}` ? (
          <NothingFound
            title="No program found"
            text="To view this page, first select an account from your Dashboard."
            linkText="View Dashboard"
            to="/"
          />
        ) : null
      }
    >
      {profile && projectData && (
        <div className="bg-cloud-50 overflow-x-hidden">
          <ProjectNotification
            data={data}
            projectData={projectData}
            projectId={projectId}
          />

          <ProjectDetailsHero
            data={projectData}
            attestationsData={attestationsData}
            accountId={accountId}
            projectId={projectId}
            accountRole={account?.role}
            profile={profile}
            similarPrograms={similarPrograms}
            stackablePrograms={stackablePrograms}
            isMultiAccount={isMultiAccount}
            eligibilityDialog={eligibilityDialog}
            acceptInvite={acceptInvite}
            setAcceptInvite={setAcceptInvite}
            invite={invite}
          />

          <Pagination
            prevTo={
              prevPage && isMultiAccount
                ? `/accounts/${accountId}/programs/${prevPage}`
                : prevPage && !isMultiAccount
                  ? `/programs/${prevPage}`
                  : undefined
            }
            nextTo={
              nextPage && isMultiAccount
                ? `/accounts/${accountId}/programs/${nextPage}`
                : nextPage && !isMultiAccount
                  ? `/programs/${nextPage}`
                  : !nextPage && isMultiAccount
                    ? `/accounts/${accountId}/programs/browsed-all`
                    : !nextPage && !isMultiAccount
                      ? "/programs/browsed-all"
                      : undefined
            }
            prevLinkText="Previous program"
            nextLinkText="Next program"
            className="mb-6 lg:mb-12"
          />

          <InvitationBanner
            invite={invite}
            setAcceptInvite={setAcceptInvite}
            landownerStatus={projectData?.landowner_status}
          />

          <div className="container px-5 lg:flex xl:max-w-1120 lg:justify-between">
            <ProjectDetailsContent
              overviewInformation={projectData?.overview_information}
              contractDetails={projectData?.contract_details}
              projectId={projectId}
            />

            <ProjectDetailsSidebar
              projectData={projectData}
              roiData={roiData}
              roiDialog={roiDialog}
              showRoi={showRoi}
              stackablePrograms={stackablePrograms}
              isMultiAccount={isMultiAccount}
              accountId={accountId}
            />
          </div>

          <ProjectMap
            isLoading={tileIsLoading || parcelIsLoading}
            tileData={tileData}
            parcelData={parcelData}
            dialog={howWeAssessYourLandDialog}
            totalAcreage={property?.acreage}
            eligibleAcres={projectData?.eligible_acres}
            isIneligible={
              projectData?.badge_display === LANDOWNER_STATUS.ineligible
            }
            ineligibilityReasons={projectData?.ineligibility_reasons}
            badgeDisplay={projectData?.badge_display}
            eligibilityDialog={eligibilityDialog}
          />

          <ProjectDetailsTabs profileId={profile?.id} />

          {similarPrograms?.length > 0 ? (
            <div className="container sm:flex xl:max-w-1120 mt-20 sm:mt-[66px]">
              <SimilarProgramsSlider
                programs={similarPrograms}
                isMultiAccount={isMultiAccount}
                accountId={accountId}
                header={
                  <>
                    <hr className="w-full-40 sm:w-full border-charcoal-50 mx-5 sm:mx-0 mb-12" />

                    <h4 className="mb-6 px-5 sm:px-0 text-2xl leading-120 tracking-0.48">
                      Similar Programs
                    </h4>
                  </>
                }
              />
            </div>
          ) : null}
        </div>
      )}

      <BackToTop scrollY={400} />

      <HowWeAssessYourLandModal
        dialog={howWeAssessYourLandDialog}
        assessmentCriteria={projectData?.algorithmic_requirements}
      />

      {showRoi ? (
        <RoiModal
          roiDialog={roiDialog}
          roiData={roiData}
          isUrgent={isUrgent}
          roiCalcDefaults={roiCalcDefaults}
        />
      ) : null}
    </MainLayout>
  )
}

export default ProjectDetails
